<template>
  <main v-loading="loading.order" class="member-shop-order-detail">
    <PageTitle
      title="訂單詳情"
      icon="chevron_left"
      btn2="訂單紀錄"
      @iconClick="$router.push({
        name: 'MemberShopOrderManage'
      })"
      @btn2Click="dialog.history = true"
    >
      // v-show="checkCode('ACT_ORDER_CANCELLED')"
      <template #btn1>
        <BaseElButton
          class="text-normal"
          type="primary"
          :disabled="get(orderData, 'status') === 'cancel'"
          @click="openCancelDialog"
        >
          取消訂單
        </BaseElButton>
      </template>
    </PageTitle>
    <div v-loading="loading.order" class="content-wrapper">
      <MemberShopOrderInfoSection :order="orderData" :actions="availableActions" />
      <MemberShopOrderCustomerInfoSection :order="orderData" :actions="availableActions" />
      <MemberShopOrderPaymentInfoSection :order="orderData" :actions="availableActions" @refresh="refresh" />
      <MemberShopOrderCustomFlowSection :order="orderData" :customFlowConfig="displayCustomFlowConfig" @refresh="refresh" />
      <MemberShopOrderInvoiceInfoSection :order="orderData" :actions="availableActions" @refresh="refresh" />
      <MemberShopOrderProductsTable
        :products="get(orderData, 'MemberStoreOrderProducts')"
        :actions="availableActions"
        :productDetail="orderProuctDetail"
        @refresh="refresh"
      />
      <MemberShopOrderStatisticSection :order="orderData" />
    </div>

    <MemberShopOrderHistoryModal
      v-if="dialog.history"
      :logs="orderLogs"
      :products="get(orderData, 'MemberStoreOrderProducts')"
      @close="dialog.history = false"
    />

    <WarningDialog
      v-if="dialog.cancel"
      title="提醒"
      :width="isClassTicket ? '700px': '440px'"
      content="提醒，是否確定要取消此訂單？"
      :confirmBtnLoading="loading.cancel"
      @close="dialog.cancel = false"
      @confirm="cancelOrder"
    >
      <template v-if="isClassTicket" v-slot:body>
        <p class="mt-[24px] mb-[16px]">此訂單剩餘商品資訊如下：</p>
        <BaseTable :data="displayData" class="mb-[20px]">
          <BaseElTableColumn label="堂票編號" prop="code" align="center" width="120px" />
          <BaseElTableColumn label="未使用 / 總數" prop="serialUsed" align="center" />
          <BaseElTableColumn label="價保核銷" prop="promise" align="center" />
          <BaseElTableColumn label="作廢" prop="cancelSerialCount" align="center" />
          <!-- <BaseElTableColumn label="未使用" prop="openSerialCount" align="center" /> -->
          <!-- <BaseElTableColumn label="已過期" prop="overdueSerialCount" align="center" /> -->
        </BaseTable>
        <!-- 取消授權 -->
        <p v-if="showCancelAuthorize">提醒：取消此訂單後，請至付款資訊進行取消授權，或手動退還未使用 {{ openSerialCount }} 張堂票或等值金額給會員。<span class="text-danger font-bold">（注意：取消授權時，將退回全額給顧客。）</span></p>
        <!-- 有剩餘未使用堂票 -->
        <p v-else-if="openSerialCount > 0">提醒：若取消此訂單，需手動退還未使用 {{ openSerialCount }} 張堂票或等值金額給會員。</p>
        <!-- 無剩餘未使用堂票 -->
        <p v-else>提醒：取消此訂單後，因無未使用商品，無需手動退費給會員。</p>
      </template>
    </WarningDialog>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import MemberShopOrderInfoSection from './components/MemberShopOrderInfoSection.vue'
import MemberShopOrderCustomerInfoSection from './components/MemberShopOrderCustomerInfoSection.vue'
import MemberShopOrderPaymentInfoSection from './components/MemberShopOrderPaymentInfoSection.vue'
import MemberShopOrderInvoiceInfoSection from './components/MemberShopOrderInvoiceInfoSection.vue'
import MemberShopOrderProductsTable from './components/MemberShopOrderProductsTable.vue'
import MemberShopOrderStatisticSection from './components/MemberShopOrderStatisticSection.vue'
import MemberShopOrderHistoryModal from './components/MemberShopOrderHistoryModal.vue'
import MemberShopOrderCustomFlowSection from './components/MemberShopOrderCustomFlowSection.vue'
import store from '@/store'
import {
  FindMemberShopOrder,
  FindMemberShopOrderLogs,
  FindMemberShopOrderTransitionCodes,
  FindMemberShopOrderProductDetail,
  CancelMemberShopOrder,
} from '@/api/memberShop'
import { useTransitionCode } from './utils'
import { useRoute } from 'vue-router/composables'
import { get, isEmpty, isNull, flatMap, map, reduce } from 'lodash'
import { useCustomFlow } from '@/use/useCustomFlow'

export default defineComponent({
  name: 'MemberShopOrderDetail',
  components: {
    PageTitle,
    MemberShopOrderInfoSection,
    MemberShopOrderCustomerInfoSection,
    MemberShopOrderPaymentInfoSection,
    MemberShopOrderInvoiceInfoSection,
    MemberShopOrderStatisticSection,
    MemberShopOrderProductsTable,
    MemberShopOrderHistoryModal,
    MemberShopOrderCustomFlowSection,
    WarningDialog,
  },
  setup (props) {
    const route = useRoute()
    const { customFlowConfig, displayCustomFlowConfig, getCustomFlowConfig, useCustomFlowFeature } = useCustomFlow('memberStore')
    const shopId = computed(() => store.getters.shop)
    const orderId = computed(() => route.params.id)
    const loading = reactive({
      order: false,
      cancel: false,
    })
    const orderData = ref(null)
    const orderLogs = ref([])
    const availableActions = ref([])
    const dialog = reactive({
      history: false,
      cancel: false,
    })
    const orderProuctDetail = ref({})

    const checkCode = computed(() => {
      const actions = availableActions.value
      const { actionCode } = useTransitionCode(actions)
      return (code) => actionCode(code)
    })
    const combinedClassTicketRecordSummaries = computed(() => {
      const data = get(orderProuctDetail, 'value')
      return flatMap(data, 'classTicketRecordSummaries')
    })
    const isClassTicket = computed(() => {
      return !isEmpty(combinedClassTicketRecordSummaries.value)
    })

    const displayData = computed(() => {
      return map(combinedClassTicketRecordSummaries.value, item => {
        const code = get(item, 'code')
        const serialUsed = `${get(item, 'openSerialCount')} / ${get(item, 'availableTimes')}`
        const promise = get(item, 'promiseCount') === 0 ? '-' : `${get(item, 'promiseUsedCount')} / ${get(item, 'promiseCount')}`
        const cancelSerialCount = get(item, 'cancelSerialCount')
        const openSerialCount = get(item, 'openSerialCount')
        const overdueSerialCount = get(item, 'overdueSerialCount')

        return {
          code,
          serialUsed,
          promise,
          cancelSerialCount,
          openSerialCount,
          overdueSerialCount,
        }
      })
    })

    const openSerialCount = computed(() => {
      return reduce(combinedClassTicketRecordSummaries.value, (count, item) => {
        return count + get(item, 'openSerialCount', 0)
      }, 0)
    })

    const showCancelAuthorize = computed(() => {
      // 判斷是否顯示 1.當日交易 2.是否為永豐
      const isSinoPacEpos = get(orderData.value, 'MemberStoreOrderPayment.paymentType') === 'sinoPacEpos'
      const createdAt = get(orderData.value, 'createdAt')
      const isToday = new Date(createdAt).getDate() === new Date().getDate()
      return isSinoPacEpos && isToday
    })
    const openCancelDialog = () => {
      dialog.cancel = true
    }

    const findOrder = async (payload) => {
      const [res, err] = await FindMemberShopOrder(payload)
      if (err) return window.$message.error(err)
      orderData.value = res
    }

    const findOrderLogs = async (payload) => {
      const [res, err] = await FindMemberShopOrderLogs(payload)
      if (err) return window.$message.error(err)
      orderLogs.value = res
    }

    const findOrderTransitionCodes = async (payload) => {
      const [res, err] = await FindMemberShopOrderTransitionCodes(payload)
      if (err) return window.$message.error(err)
      availableActions.value = res.transitionCodes
    }
    const findMemberShopOrderProductDetail = async (payload) => {
      const product = get(orderData.value, 'MemberStoreOrderProducts')
      for (const item of product) {
        const newPayload = {
          ...payload,
          memberStoreOrderProductId: get(item, 'id'),
        }
        const [res, err] = await FindMemberShopOrderProductDetail(newPayload)
        if (err) return window.$message.error(err)
        orderProuctDetail.value[get(item, 'id')] = res
      }
    }

    const cancelOrder = async () => {
      loading.cancel = true
      const [, err] = await CancelMemberShopOrder({
        shopId: shopId.value,
        id: orderId.value,
      })
      if (err) {
        loading.cancel = false
        window.$message.error(err)
        return
      }
      window.$message.success('已取消訂單！')
      dialog.cancel = false
      refresh()
      loading.cancel = false
    }

    const refresh = async () => {
      const payload = {
        shopId: shopId.value,
        id: orderId.value,
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
      }
      loading.order = true
      await Promise.allSettled([
        findOrder(payload),
        findOrderLogs(payload),
        findOrderTransitionCodes(payload),
        getCustomFlowConfig(),
      ])
      await findMemberShopOrderProductDetail(payload)
      loading.order = false
    }

    onMounted(async () => {
      await refresh()
    })
    return { get, dialog, orderData, checkCode, availableActions, loading, orderLogs, cancelOrder, refresh, isClassTicket, displayData, orderProuctDetail, openSerialCount, showCancelAuthorize, customFlowConfig, displayCustomFlowConfig, openCancelDialog }
  },
})
</script>

<style scoped lang="postcss">
.content-wrapper {
  @apply flex flex-col gap-[16px];
}
</style>
